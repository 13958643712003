<template>
  <div class="w-100">
    <h1 class="mt-4 font-large-3 text-center text-warning">
      🛠 Under construction 🛠
    </h1>
    <h5 class="text-center text-warning">
      ...coming soon to a project near you!
    </h5>
  </div>
</template>

<script>
export default {
  name: 'General',
}
</script>
